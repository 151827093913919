import React from 'react';
import { Link } from 'gatsby';
//imgs
import footerLogo from '../images/footer-imgs/footerLogo.svg';
import twitter from '../images/footer-imgs/tw-ico.svg';
import fb from '../images/footer-imgs/fb-ico.svg';
import ig from '../images/footer-imgs/ig-ico.svg';
import yt from '../images/footer-imgs/yt-ico.svg';
import { StaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

export default function Footer() {
    return (
        <StaticQuery
            query={graphql`
            query Footer {
              footer: markdownRemark(fileAbsolutePath: {regex: "/footer/"}) {
                frontmatter{
                  logo_descript
                  images{
                    logo_image{
                      childImageSharp{
                        gatsbyImageData(width: 150, layout: FIXED)
                      }
                    }
                  }
                }
              }
            }
          `}
            render={data => (
                <footer id="footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-3 col-md-1">
                                <a href="https://www.icanw.org/">
                                    <img src={footerLogo} alt="ICANW" className="foot-logo" />
                                </a>
                            </div>
                            <div className="col-9 col-md-11">
                                <div className="row justify-content-between">
                                    <div className="col-12 col-md-6">
                                        <ul className="footerNav d-flex">
                                            <li className="nav-item">
                                                <Link className="nav-link" to="/">Map</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link className="nav-link" to="/methodology">Methodology</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link className="nav-link" to="/faq">FAQ</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link className="nav-link" to="/further-reading">Further Reading</Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="col-12 col-md-6">
                                        <div class="footer-funder-block">
                                            <span>{data.footer.frontmatter.logo_descript}</span>
                                            <div class="funder-logos">
                                                {
                                                    data.footer.frontmatter.images.map(item =>
                                                        <GatsbyImage image={item.logo_image.childImageSharp.gatsbyImageData} objectFit={"contain"} />
                                                    )
                                                }
                                            </div>
                                          
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-md-8">
                                        <p>The International Campaign to Abolish Nuclear Weapons (ICAN) is a coalition of
                                            non-governmental organisations in one hundred countries promoting adherence to and implementation of the United Nations Treaty on the Prohibition of Nuclear Weapons.
                                        </p>
                                        <a href="https://www.icanw.org/privacy_policy">Privacy Policy</a>
                                        <p className="pt-3">© {new Date().getFullYear()} ICAN International Campaign to Abolish Nuclear Weapons</p>
                                        <p>Built by <a href="https://www.tectonica.co/">Tectonica</a></p>
                                    </div>
                                    <div className="col-4 social-col">
                                        <ul className="d-flex">
                                            <li>
                                                <a href="https://twitter.com/nuclearban"><img src={twitter} alt="twitter" /></a>
                                            </li>
                                            <li>
                                                <a href="https://www.facebook.com/icanw.org"><img src={fb} alt="facebook" /></a>
                                            </li>
                                            <li>
                                                <a href="https://www.instagram.com/nuclearban/"><img src={ig} alt="instagram" /></a>
                                            </li>
                                            <li>
                                                <a href="https://www.youtube.com/icaninaction"><img src={yt} alt="youtube" /></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            )}
        />
    )
}