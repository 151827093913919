import React, {useState} from 'react';
import { Link } from 'gatsby';
import mobLogo from '../images/ican-header.svg';
import desktopLogo from '../images/desktopLogoNew.svg';

export default function Nav() {
const [expanded, setExpanded] = useState(false);
const handleNavClick = () => {
    setExpanded(!expanded)
}    
  return (
      <header>
          <div className="header-inner">
          <nav className="navbar navbar-expand-lg">
            <Link className="navbar-brand" to="/">
                <img src={mobLogo} alt="ICAN logo" className="mobileNavImg" />
                <img src={desktopLogo} alt="ICAN logo" className="desktopNavImg" />
            </Link>
            <button onClick={() => handleNavClick()} className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className={` ${ expanded ? 'open-toggle ' : ''}navbar-toggler-icon`}></span>
            </button>
            <div className={` ${ expanded ? 'show' : ''} collapse navbar-collapse justify-content-end`} id="navbarNav">
                <ul className="navbar-nav">
                <li className="nav-item">
                    <Link className="nav-link" to="/">Map</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link" to="/methodology">Methodology</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link" to="/faq">FAQ</Link>
                </li>
                <li className="nav-item">
                <Link className="nav-link" to="/further-reading">Further Reading</Link>
                </li>
                </ul>
            </div>
            </nav>
          </div>
      </header>
  );
}
